"use client";
// import { ToggleTheme } from "./toggleTheme";
import React, { useState } from "react";
import logo from "../public/logo.webp";
import mlogo from "../public/logo.webp";
import uae from "../public/Flag_of_the_United_Arab_Emirates.webp";
import usa from "../public/usaFlag.webp";
import Image from "next/image";

import whiteLogo from "../public/darkmodel.png";

import {
  AlignJustify,
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  Sun,
  Moon,
  Navigation,
} from "lucide-react";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useLocale, useTranslations } from "next-intl";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "./ui/navigation-menu";
import { HoveredLink, MenuItem, Menu, ProductItem } from "./ui/navbar-menu";
import { cn } from "@/lib/utils";
import { useTheme } from "next-themes";
import { Link, usePathname, useRouter } from "@/navigation";

function Nav() {
  // const {setTheme, theme} = useTheme();

  // const [isDark, setIsDark] = useState(false);
  // useEffect(() => {
  //   const root = window.document.documentElement;
  //   const theme = localStorage.getItem("theme") || "light";
  //   setIsDark(theme === "dark");

  //   root.classList.toggle("dark", theme === "dark");
  // }, []);
  const [active, setActive] = useState<string | null>(null);
  const t = useTranslations("Index");
  const pathname = usePathname();
  const router = useRouter();
  const local = useLocale();

  function switchLang() {
    if (pathname.includes("/ar/")) {
      router.push(pathname.replace("/ar/", "/en/"));
    } else if (pathname.includes("/en/")) {
      router.push(pathname.replace("/en/", "/ar/"));
    } else {
      if (local === "ar") {
        router.push(pathname.replace("ar", "en"));
      } else {
        router.push(pathname.replace("en", "ar"));
      }
    }
  }

  console.log(pathname);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  if (true)
    return (
      <>
        <NavigationMenu className="hidden shadow-md fixed left-1/2 -translate-x-1/2 z-50 md:flex mx-auto w-full top-5 gap-3 rounded-full border border-transparent dark:bg-background dark:border-white/[0.2] bg-white shadow-input  justify-center space-x-4 pl-8 pr-2 py-2 ">
          <Link href={`/`}>
            <Image
              src={logo}
              alt="logo"
              className="block dark:hidden"
              width={100}
              height={100}
              // style={{
              //   maxWidth: "100%",
              //   height: "auto",
              // }}
            />
            <Image
              src={whiteLogo}
              alt="logo"
              className="hidden dark:block"
              width={100}
              height={100}
              // style={{
              //   maxWidth: "100%",
              //   height: "auto",
              // }}
            />
          </Link>
          <NavigationMenuList className="">
            <NavigationMenuItem>
              <NavigationMenuLink
                asChild
                className={`hover:text-primary ${
                  pathname === "/"
                    ? "text-primary font-bold"
                    : "dark:text-white"
                } ${navigationMenuTriggerStyle()}`}
              >
                <Link href="/">{t("home")}</Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="text-foreground hover:text-primary">
                {t("services")}
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid w-[400px] gap-2 p-4 md:w-[500px] md:grid-cols-3 lg:w-[800px] bg-slate-50/10">
                  <li className="col-span-1 row-span-2 bg-white shadow-lg rounded-lg overflow-hidden">
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={
                          "relative flex flex-col justify-end bg-[url(../public/aws.webp)]  bg-no-repeat bg-cover rounded-lg p-6 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        }
                      >
                        <div className="absolute top-6 left-6">
                          <Image
                            src={"/aws-logo.webp"}
                            width={400}
                            height={400}
                            alt="AWS Logo"
                            className="w-8 h-5 relative z-20"
                          />
                        </div>
                        <div className="relative z-20">
                          <div className="text-center text-white mb-4">
                            <div className="text-lg font-bold">
                              {t("services")}
                            </div>
                            <div className="text-sm">{t("discover_aws")}</div>
                          </div>
                          <Button className="w-full  rounded-full bg-white hover:bg-blue-50 text-blue-800">
                            {t("discover")}
                          </Button>
                        </div>
                        <div className="absolute bottom-0 left-0">
                          <Image
                            src={"/aws-bg.svg"}
                            width={400}
                            height={400}
                            alt="AWS Logo"
                            className="w-full h-full object-contain"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>
                  <li dir={local == "en" ? "ltr" : "rtl"}>
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={cn(
                          "flex justify-between items-center bg-white shadow-lg select-none space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        )}
                      >
                        <div className="text-sm font-medium leading-none text-primary">
                          {t("service_1_title")}
                        </div>
                        <div className="w-10 h-10">
                          <Image
                            src={"/devops 1.svg"}
                            width={200}
                            height={200}
                            className="w-8 h-8"
                            alt="devops"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>
                  <li dir={local == "en" ? "ltr" : "rtl"}>
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={
                          "flex justify-between items-center bg-white shadow-lg select-none space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        }
                      >
                        <div className="text-sm font-medium leading-none text-primary">
                          {t("service_2_title")}
                        </div>
                        <div className="w-10 h-10">
                          <Image
                            src={"/security.svg"}
                            width={200}
                            height={200}
                            className="w-8 h-8"
                            alt="devops"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>
                  <li dir={local == "en" ? "ltr" : "rtl"}>
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={
                          "flex justify-between items-center bg-white shadow-lg select-none space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        }
                      >
                        <div className="text-sm font-medium leading-none text-primary">
                          {t("service_5_title")}
                        </div>
                        <div className="w-10 h-10">
                          <Image
                            src={"/web.svg"}
                            width={200}
                            height={200}
                            className="w-8 h-8"
                            alt="devops"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>
                  <li dir={local == "en" ? "ltr" : "rtl"}>
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={
                          "flex justify-between items-center bg-white shadow-lg select-none space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        }
                      >
                        <div className="text-sm font-medium leading-none text-primary">
                          {t("service_3_title")}
                        </div>
                        <div className="w-10 h-10">
                          <Image
                            src={"/cloud-service.svg"}
                            width={200}
                            height={200}
                            className="w-8 h-8"
                            alt="devops"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>

                  <li dir={local == "en" ? "ltr" : "rtl"}>
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={
                          "flex justify-between items-center bg-white shadow-lg select-none space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        }
                      >
                        <div className="text-sm font-medium leading-none text-primary">
                          {t("service_6_title")}
                        </div>
                        <div className="w-10 h-10">
                          <Image
                            src={"/mobile-development 1.svg"}
                            width={200}
                            height={200}
                            className="w-8 h-8"
                            alt="devops"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>
                  <li dir={local == "en" ? "ltr" : "rtl"}>
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={
                          "flex justify-between items-center bg-white shadow-lg select-none space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        }
                      >
                        <div className="text-sm font-medium leading-none text-primary text-start">
                          {t("service_4_title")}
                        </div>
                        <div className="w-10 h-10">
                          <Image
                            src={"/uiux-icon.svg"}
                            width={200}
                            height={200}
                            className="w-8 h-8"
                            alt="devops"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>

                  <li dir={local == "en" ? "ltr" : "rtl"}>
                    <NavigationMenuLink asChild>
                      <NavigationMenuLink
                        href="/"
                        className={
                          "flex justify-between items-center bg-white shadow-lg select-none space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        }
                      >
                        <div className="text-sm font-medium leading-none text-primary">
                          {t("service_7_title")}
                        </div>
                        <div className="w-10 h-10">
                          <Image
                            src={"/custom.svg"}
                            width={200}
                            height={200}
                            className="w-8 h-8"
                            alt="devops"
                          />
                        </div>
                      </NavigationMenuLink>
                    </NavigationMenuLink>
                  </li>
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink
                asChild
                className={`hover:text-primary ${
                  pathname === "/solution"
                    ? "text-primary font-bold"
                    : "dark:text-white"
                } ${navigationMenuTriggerStyle()}`}
              >
                <Link href="/solution">{t("solution")}</Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink
                asChild
                className={`hover:text-primary ${
                  pathname === "/pricing"
                    ? "text-primary font-bold"
                    : "dark:text-white"
                } ${navigationMenuTriggerStyle()}`}
              >
                <Link href="/pricing">{t("pricing")}</Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink
                asChild
                className={`hover:text-primary ${
                  pathname === "/contact-us"
                    ? "text-primary font-bold"
                    : "dark:text-white"
                } ${navigationMenuTriggerStyle()}`}
              >
                <Link href="/contact-us">{t("contact_us")}</Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink
                asChild
                className={`hover:text-primary ${
                  pathname === "/aboutus"
                    ? "text-primary font-bold"
                    : "dark:text-white"
                } ${navigationMenuTriggerStyle()}`}
              >
                <Link href="/aboutus">{t("about_us")}</Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuList>
          <Button
            asChild
            className="dark:text-black bg-primary rounded-full font-semibold p-6"
          >
            <Link href="/contact-us">{t("get_in_touch")}</Link>
          </Button>
        </NavigationMenu>
        <div className="flex justify-end items-center p-4 md:hidden mr-3">
          <Sheet>
            <SheetTrigger asChild>
              <Button className="text-foreground rounded-full py-7">
                <AlignJustify name="open Menu" />
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>
                  <Image
                    src={mlogo}
                    alt="logo"
                    className="block dark:hidden"
                    width={100}
                    height={100}
                    // style={{
                    //   maxWidth: "100%",
                    //   height: "auto",
                    // }}
                  />
                  <Image
                    src={whiteLogo}
                    alt="logo"
                    className="hidden dark:block"
                    width={100}
                    height={100}
                    // style={{
                    //   maxWidth: "100%",
                    //   height: "auto",
                    // }}
                  />
                </SheetTitle>

                <SheetDescription>
                  <div>
                    <ul className="mt-8">
                      <li className="mb-8 text-right font-bold">
                        <Link href={`/${local}`}>{t("home")}</Link>
                      </li>
                      <li className="mb-8 text-right font-bold">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              className="p-0 h-[20px] text-base font-normal m-0 border-none bg-transparent text-inherit hover:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
                              variant="outline"
                            >
                              {t("services")}
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56">
                            <DropdownMenuGroup
                              dir={local == "ar" ? "rtl" : "ltr"}
                            >
                              <DropdownMenuItem>
                                <span>{t("service_1_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_2_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_3_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_4_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_5_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_6_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_7_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_devops_1_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_devops_2_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_devops_3_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_devops_4_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_devops_5_title")}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <span>{t("service_devops_6_title")}</span>
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        </DropdownMenu>
                        <Button
                          className="p-0 h-[20px] text-[16px]  m-0 border-none bg-transparent text-inherit hover:bg-transparent"
                          onClick={() => scrollToSection("services")}
                        >
                          <span>{t("services")} </span>
                        </Button>
                      </li>
                      <li className="mb-8 text-right font-bold">
                        <Button
                          className="p-0 h-[20px] text-[16px]  m-0 border-none bg-transparent text-inherit hover:bg-transparent"
                          onClick={() => scrollToSection("solutions")}
                        >
                          <span>{t("solution")} </span>
                        </Button>
                      </li>
                      <li className="mb-8 text-right font-bold">
                        <Button
                          className="p-0 h-[20px] text-[16px]  m-0 border-none bg-transparent text-inherit hover:bg-transparent"
                          onClick={() => scrollToSection("marketplace")}
                        >
                          <span>{t("marketplace")} </span>
                        </Button>
                      </li>
                      <li className="mb-8 text-right font-bold">
                        <Link href={`/${local}/pricing`}>{t("pricing")}</Link>
                      </li>
                      <li className="mb-8 text-right font-bold">
                        <Link href={`/${local}/contact-us`}>
                          {t("contact_us")}
                        </Link>
                      </li>
                      <li className="mb-8 text-right font-bold">
                        <Link href={`/${local}/aboutus`}>{t("about_us")}</Link>
                      </li>
                    </ul>
                  </div>
                </SheetDescription>
              </SheetHeader>
            </SheetContent>
          </Sheet>
        </div>
      </>
    );
}

export default Nav;
